body,
html {
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

.loader:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader 2s linear infinite;
}

.displayMenu {
  width: 100%;
  background-color: #fff !important;
  position: absolute;
  top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 0.5s ease-in-out;
  z-index: 12;
  overflow: hidden;
  color: black;
  gap: 2rem;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.homeContent {
  min-height: calc(100vh - 120px) !important;
  position: relative;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  appearance: textfield;
}

@media screen and (max-width: 976px) {
  .homeContent {
    min-height: calc(100vh - 80px) !important;
    position: relative;
  }

  .faqContent {
    display: none;
    width: 0px;
    height: 0px;
  }

  .comingSoon {
    visibility: hidden;
    width: 0px;
    height: 0px;
  }
}
/* faqContent */

@media screen and (min-width: 976px) {
  .faqContent {
    display: block;
    width: 100%;
    height: 100%;
  }
  .comingSoon {
    visibility: visible;
  }
}

/* Start of hamburger menu section */

.burger_Menu {
  margin-left: 10px;
  width: fit-content;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  gap: 0.5em;
}

@media (max-width: 976px) {
  .burger_Menu {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    transition: all 0.5s ease;
    gap: 0.5em;
  }
}

@media (min-width: 976px) {
  .burger_Menu {
    display: none;
  }
}

/* start of districts responsiveness  */

.district {
  width: 100px;
}

@media only screen and (min-width: 350px) and (max-width: 379px) {
  .gakenke {
    margin-left: 3rem;
  }
  .kamonyi {
    margin-left: 3rem;
  }
  .district {
    width: fit-content;
  }
}

@media only screen and (min-width: 380px) and (max-width: 400px) {
  .gakenke {
    margin-left: 3.5rem;
  }
  .kamonyi {
    margin-left: 3.5rem;
  }
  .district {
    width: fit-content;
  }
}

@media only screen and (min-width: 400px) and (max-width: 410px) {
  .gakenke {
    margin-left: 3rem;
  }
  .kamonyi {
    margin-left: 3rem;
  }
}

@media only screen and (min-width: 411px) and (max-width: 440px) {
  .gakenke {
    margin-left: 5rem;
  }
  .kamonyi {
    margin-left: 5rem;
  }
}

@media only screen and (min-width: 976px) and (max-width: 1137px) {
  .gakenke {
    margin-left: 2rem;
  }
}

/* end of districts responsiveness */

.burger_Bar {
  /* -bottom: 0.5em; */
  width: 1.1em;
  height: 0.2em;
  background-color: #dfda53;
  transition: all 0.5s ease;
}
.burger_Bar:nth-child(2) {
  /* margin-bottom: 0.5em; */
  width: 2em;
  height: 0.2em;
  background-color: #dfda53;
  transition: all 0.5s ease;
}
.menu {
  width: 100%;
  display: flex;
}

/* CLICKED */

.burger_Bar.clicked:nth-child(1) {
  transform: rotate(45deg) translate(0.2em, 1em);
  transition: all 0.5s ease;
  width: 1.5em;
  background-color: #dfda53;
}

.burger_Bar.clicked:nth-child(2) {
  display: none;
}

.burger_Bar.clicked:nth-child(3) {
  transform: rotate(132deg) translate(0.5em, 0.35em);
  transition: all 0.5s ease;
  width: 1.5em;
  background-color: #dfda53;
}

/* UNCLICKED  */
.burger.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

.instagram-media.instagram-media-rendered,
.rsme-instagram-embed {
  width: 350px !important; /* Override width */
}
.Embed {
  width: 350px !important;
}
